.center-section2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-direction: column;
    position: relative;
    top: 0px;
}
.section2 div{
    width: 100%;
}
.section2{
position: relative;
width: 600px;
border: 3px solid #FFFFFF;
border-radius: 51px;
display: flex;
justify-content: flex-start;
align-content: center;
align-items: flex-start;
flex-direction: column;
padding: 20px ;
}
.section2-title{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 700;
font-size: 38px;
line-height: 80px;
/* or 208% */
width: 100%;
display: flex;
align-items: center;

color: #FFFFFF;
}
label{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 50px;
/* or 333% */
margin-top: 10px;
display: flex;
align-items: center;

color: #FFFFFF;
}
input, label, textarea {
    display:block;
  
}
input{
    background: #FFFFFF;
border-radius: 10px;
height: 35px;
width: 100% !important;
border: none;
padding: 5px;
}
textarea{
    background: #FFFFFF;
border-radius: 10px;
height: 95px;
padding: 5px;
width: 100% !important;
border: none;

}
.form-btn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;

}
.form-btn div{
    background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
border-radius: 15px;
position: relative;
right: 0;
font-family: 'SatoshiB';
font-style: normal;
font-weight: 500;
font-size: 20.096px;
line-height: 39px;
/* identical to box height, or 131% */
padding: 2px 50px;
text-align: center;
width: max-content !important;
margin-top: 15px;
color: #000000;
cursor: pointer;
}
.form-btn div a{
color: black;
text-decoration: none;
}
.connect-wallet{
    position: relative;
     top: 20px;
     right: 20px;

    /* identical to box height, or 168% */

    color: #FFFFFF;
}
.connect-wallet a{
    color: #fff !important;
    text-decoration: none;
  
}
@media screen and (max-width:1200px){
    .center-section2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;  
        flex-direction: column;
        position: relative;
        top: 60px;
        /* left: 50%;
        transform: translateX(-50%); */
    }
    .section2 div{
        width: 100%;
    }
    .section2{
    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
    width:80%;
    
    border: 3px solid #FFFFFF;
    border-radius: 51px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px ;
    margin-bottom: 20px;
    }
    .section2-title{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
    /* or 208% */
    width: 100%;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    }
    label{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 50px;
    /* or 333% */
    margin-top: 10px;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    }
    input, label, textarea {
        display:block;
      
    }
    input{
        background: #FFFFFF;
    border-radius: 10px;
    height: 20px;
    width: 100% !important;
    border: none;
    padding: 5px;
    }
    textarea{
        background: #FFFFFF;
    border-radius: 10px;
    height:55px;
    padding: 5px;
    width: 100% !important;
    border: none;
    
    }
    .form-btn{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: flex-end;
    }
    .form-btn div{
        background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
    border-radius: 15px;
    position: relative;
    right: 0;
    font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 500;
    font-size: 12.096px;
    line-height: 39px;
    /* identical to box height, or 131% */
    padding: 2px  30px;
    text-align: center;
    width: max-content !important;
    margin-top: 15px;
    color: #000000;
    }
    .form-btn div a{
    color: black;
    text-decoration: none;
    }
    .connect-wallet{
        position: relative;
         top: 10px;
         right: 5px;
    
        /* identical to box height, or 168% */
    
        color: #FFFFFF;
    }
    .connect-wallet a{
        color: #fff !important;
        text-decoration: none;
      
    }

}