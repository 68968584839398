@font-face {
    font-family: "SatoshiB";
    src: url(../../Satoshi-Black.otf);
}
@font-face {
    font-family: "SatoshiL";
    src: url(../..//Satoshi-Light.otf);
}
.section1-body{

    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}
.section1{
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    width: 100%;
 
}
.section1-header{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
 
}
.section1-left{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    left: 100px;
}
.section1-left div{
    margin-top: 20px;
}
.section1-left-title{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 900;
font-size: 60px;
line-height: 80px;
/* or 128% */
color: #FFFFFF;
}
.section1-left-text{
    font-family: 'SatoshiL';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 35px;
/* or 131% */
width:60%;

color: #FFFFFF;
}

.section1-left-btn{
    background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
    border-radius: 15px;
    padding: 8px 14px;
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 500;
font-size: 26px;
line-height: 47px;
/* identical to box height, or 131% */
cursor: pointer;

color: #000000;

}
.section1-left-btn a{
    color: #000 !important;
    text-decoration: none;
}

.arrow{
    width: 200px;
    height: auto;
    position: relative;
    left: 150px;
}
.section1-left-bottom{
    font-family: 'SatoshiL';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 40px;
/* or 147% */
text-decoration: dashed;

color: #FFFFFF;
}
.section1-left-bottom a{

color: #FFFFFF !important;
}
.ring{
    width: 90%;
    position: relative;
    right: 100px;
}
.wallet{
    color: white;
    overflow: hidden;
    border: 2px solid #FFFFFF; 
    padding: 0px 10px;
    font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    border-radius: 10px;
    position: relative;
    top: 20px;
    right: 20px;
}
.wallet a{
    color: #fff !important;
    text-decoration: none;
  
}

@media screen and (max-width:1200px){
    .section1-body{
       
        display: flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
       
    }
    .section1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;

     
        width: 100%;
     
    }
    .section1-header{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
     
    }
    .section1-left{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        left: 0px;
    }
    .section1-left div{
        margin-top: 20px;
    }
    .section1-left-title{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 50px;
    width:80%;
    color: #FFFFFF;
    }
    .section1-left-text{
        font-family: 'SatoshiL';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    /* or 131% */
    width:80%;
    
    color: #FFFFFF;
    }
    
    .section1-left-btn{
        background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
        border-radius: 15px;
        padding: 8px 14px;
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 47px;
    /* identical to box height, or 131% */
    cursor: pointer;
    
    color: #000000;
    
    }
    .section1-left-btn a{
        color: #000 !important;
        text-decoration: none;
    }
    
    .arrow{
        width: 100px;
        height: auto;
        position: relative;
        left: 20px;
    }
    .section1-left-bottom{
        font-family: 'SatoshiL';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 147% */
    text-decoration: dashed;
    margin-bottom: 15px;
    color: #FFFFFF;
    }
    .section1-left-bottom a{
    
    color: #FFFFFF !important;
    }
    .ring{
        width: 80%;
        position: relative;
        right: 0px;
        left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
    }
    .wallet{
        color: white;
        overflow: hidden;
        border: 2px solid #FFFFFF; 
        padding: 0px 10px;
        font-family: 'SatoshiB';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 40px;
        border-radius: 10px;
        position: relative;
        top: 20px;
        right: 20px;
    }
    .wallet a{
        color: #fff !important;
        text-decoration: none;
      
    }
}