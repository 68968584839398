.section4-row-fields div{
    display: flex;
    justify-content: space-between;
    align-items: center;  
    flex-direction: column;
width: 100%;

}
.last-page-center{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-direction: column;
    position: relative;
    margin-top: 60px;

}
.section4-row-fields{
    display: flex;
    justify-content: center;
    align-items: center;  
    flex-direction: row;
 
    width: 1000px;
    
    padding: 10px;
}
.section4-row-fields div div{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height:70px;
/* or 333% */
width: 100%;
display: flex;
align-items: center;

color: #FFFFFF;
}
.claim-btn{
  padding: 2px 50px !important;
  line-height:45px  !important;
}
.section4-row{
    display: flex;
    justify-content: space-between;
    align-items: center;  
    flex-direction: row;  
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
}
.section4{

    margin-bottom: 50px;
}
.display{
    display: flex;
}
.display2{
    display: none;
}
@media screen and (max-width:1200px){
    .display{
        display: none !important;
    }
    .display2{
        display: flex;
    }
    .section4-row{
        display: flex;
        justify-content: center;
        align-items: center;  
        flex-direction: column;  
        margin-top: 30px;
        padding: 10px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
    }
    .section4-row-fields div{
        display: flex;
        justify-content: center;
        align-items:center;  
        flex-direction: column;
        text-align: center;
     

    
    }
    .last-page-center{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;  
        flex-direction: column;
        position: relative;
        top: 60px;
    
    }
    .section4-row-fields{
        display: flex;
        justify-content: center;
        align-items: flex-end;  
        flex-direction: row;
 
        width: 100%;
        margin-top: 0px;
    }
    .section4-row-fields div div{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height:35px;
    /* or 333% */
    /* width: 300px; */
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    }
    .claim-btn{
      padding: 2px 30px !important;
      line-height:40px  !important;
    }
}