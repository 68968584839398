.centre{
    /* display: flex;
    justify-content: center;
    align-items: center;  */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

}
.middle-text{
    
}
.section3-row-fields{
    display: flex;
    justify-content: space-evenly;
    align-items: center;  
}
.section3-title-header{
    display: flex;
justify-content: space-between;
align-items: center;
}
.section3 div{
    width: 100%;
}
.section3{
    position: relative;
width: 1000px;
border: 3px solid #FFFFFF;
border-radius: 51px;
display: flex;
justify-content: flex-start;
align-content: center;
align-items: flex-start;
flex-direction: column;
padding: 20px ;
}
.section3-title{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 700;
font-size: 38px;
line-height: 80px;
/* or 208% */
width: 100%;
display: flex;
align-items: center;

color: #FFFFFF;
}
label{
    font-family: 'SatoshiB';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 50px;
/* or 333% */
margin-top: 10px;
display: flex;
align-items: center;

color: #FFFFFF;
}
input, label, textarea {
    display:block;
  
}
input{
    background: #FFFFFF;
border-radius: 10px;
height: 35px;
width: 100% !important;
border: none;
padding: 5px;
}
textarea{
    background: #FFFFFF;
border-radius: 10px;
height: 95px;
padding: 5px;
width: 100% !important;
border: none;

}
.form-btn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;
}
.form-btn div{
    background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
border-radius: 15px;
position: relative;
right: 0;
font-family: 'SatoshiB';
font-style: normal;
font-weight: 500;
font-size: 20.096px;
line-height: 44px;
/* identical to box height, or 131% */
/* padding: 2px 50px; */
text-align: center;
width: max-content !important;
margin-top: 20px;
color: #000000;
}
.input2{
    width: 90% !important;
}
.add-another{
    margin-right: 20px;
    background: transparent !important;
    border: 2px solid #FFFFFF;
border-radius: 15px;
line-height: 40px !important;
color: #fff !important;
}
.upload{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.upload div{
    font-family: 'SatoshiL';
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 40px;
/* identical to box height, or 131% */
padding: 2px 15px;
text-align: right;
border: 2px solid #FFFFFF;
border-radius: 10px;
width: max-content;
color: #FFFFFF;

}
.export{
    position: relative;
    top: 5px;
}
@media screen and (max-width:1200px){
    .section3-row-fields{
        display: flex;
        justify-content: center;
        align-items: center;  
        flex-direction: column;
    }
    .section3-title-header{
        display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    }
    .section3 div{
        width: 100%;
    }
    .section3{
        position: relative;
    width: 80%;
    border: 3px solid #FFFFFF;
    border-radius: 51px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px ;
    }
    .section3-title{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    /* or 208% */
    width: 100%;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    }
    label{
        font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    /* or 333% */
    margin-top: 10px;
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    }
    input, label, textarea {
        display:block;
      
    }
    input{
        background: #FFFFFF;
    border-radius: 10px;
    height: 35px;
    width: 100% !important;
    border: none;
    padding: 5px;
    }
    textarea{
        background: #FFFFFF;
    border-radius: 10px;
    height: 95px;
    padding: 5px;
    width: 100% !important;
    border: none;
    
    }
    .form-btn{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: flex-end;
        flex-direction: column;
    }
    .form-btn div{
        background: linear-gradient(268.5deg, #FFFFFF -11.69%, #AA9CFF 112.48%);
    border-radius: 15px;
    position: relative;
    right: 0;
    font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 500;
    font-size: 20.096px;
    line-height: 44px;
    /* identical to box height, or 131% */
    /* padding: 2px 50px; */
    text-align: center;
    width: max-content !important;
    margin-top: 20px;
    color: #000000;
    }
    .input2{
        width: 90% !important;
    }
    .add-another{
        margin-right: 0px;
        background: transparent !important;
        border: 2px solid #FFFFFF;
    border-radius: 15px;
    line-height: 40px !important;
    color: #fff !important;
    }
    .upload{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    
    .upload div{
        font-family: 'SatoshiL';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    /* identical to box height, or 131% */
    padding: 2px 15px;
    text-align: right;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    width: max-content;
    color: #FFFFFF;
    margin-top: 15px;
    
    }
    .export{
        position: relative;
        top: 5px;
    } 
}