$midnight: #2c3e50;
$clouds: #ecf0f1;


.wallet-btn {
  position: absolute;
  opacity: 0;
  z-index: 100;
}
// Layout

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
  z-index: 100;

}
.tab {
  width:max-content;
  margin-top:0 !important;
  text-align: center;
  color: white;
  overflow: hidden;
  border: 2px solid #FFFFFF;

  border-radius: 10px;
  &-label {
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    padding: 0px 10px;
    font-family: 'SatoshiB';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
  
    cursor: pointer;
    /* Icon */
    &:hover {
     
    }
    &::after {
      content: "\276F";
   
      text-align: center;
      transition: all .35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $midnight;
    width: max-content;
    background: transparent;
    transition: all .35s;
    color: white;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {

    }
  }
  position: relative;
  z-index: 100;
}

// :checked
.wallet-btn:checked {
  + .tab-label {

    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }

}
@media screen and (max-width:1200px){}